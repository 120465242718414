import { Dialog, Transition } from '@headlessui/react';
import { LibraryIcon } from '@heroicons/react/outline';
import { apiAddManualLoanServicer } from 'api/loansAPI';
import LoadingButton from 'components/Buttons/LoadingButton';
import { Fragment, useState } from 'react';
import toast from 'react-hot-toast';

interface AddManualLoanServicerModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  refreshAccountData: () => void;
}

const AddManualLoanServicerModal: React.FC<AddManualLoanServicerModalProps> = ({
  open,
  setOpen,
  refreshAccountData,
}) => {
  const [error, setError] = useState('');

  const [isSubmittingNewManualLoanServicer, setIsSubmittingNewManualLoanServicer] = useState(false);

  const [values, setValues] = useState({
    merchantId: 'ins_116304',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const handleClose = () => {
    setOpen(false);
    setError('');
  };

  const handleSubmitNewManualLoan = async (event: React.SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();

    setIsSubmittingNewManualLoanServicer(true);
    apiAddManualLoanServicer(values.merchantId).then(
      (data) => {
        setOpen(false);
        toast.success(`Successfully added new manual student loan!`);
        setIsSubmittingNewManualLoanServicer(false);
        refreshAccountData();
      },
      (error) => {
        setError(error.message);
        setIsSubmittingNewManualLoanServicer(false);
      }
    );
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={handleClose}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full">
              <form onSubmit={handleSubmitNewManualLoan}>
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                      <LibraryIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                        Add Manual Student Loan Servicer
                      </Dialog.Title>

                      <div className="mt-4">
                        <label htmlFor="merchantId" className="block text-sm font-medium text-gray-700">
                          Loan Servicer
                        </label>
                        <select
                          id="merchantId"
                          name="merchantId"
                          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                          value={values.merchantId}
                          onChange={handleChange}
                        >
                          <option value="ins_116304">EdFinancial Services - Federal Direct Loan Program (FDLP)</option>
                          <option value="ins_136433">Mohela (New)</option>
                          <option value="ins_116866">Mohela (Legacy)</option>
                          <option value="ins_116248">Navient - Loans</option>
                          <option value="ins_116528">Nelnet - Education Financing</option>
                          <option value="ins_132249">Aidvantage</option>
                          <option value="ins_116295">Firstmark Services</option>
                          <option value="ins_116944">Sallie Mae</option>
                          <option value="ins_116464">MOHELA - SoFi Servicing</option>
                          <option value="ins_116947">MOHELA - Laurel Road</option>
                          <option value="unsaddl_ins_636162">Aspire</option>
                          <option value="unsaddl_ins_544880">Citizens</option>
                          <option value="unsaddl_ins_416602">Earnest</option>
                          <option value="unsaddl_ins_517682">NaviRefi</option>
                          <option value="unsaddl_ins_274736_new">PenFed</option>
                          <option value="unsaddl_ins_277788">HESAA-NJCLASS</option>
                          <option value="unsaddl_ins_420220">College Ave</option>
                          <option value="unsaddl_ins_190467">U-fi</option>
                          <option value="unsaddl_ins_671384">First Tech Federal Credit Union</option>
                          <option value="ins_116945">Oklahoma Student Loan Authority - Direct Loan</option>
                          <option value="ins_116950">CommonBond Firstmark Services</option>
                          <option value="ins_116319">American Education Services</option>
                          <option value="unsaddl_ins_831946">LendKey</option>
                          <option value="unsaddl_ins_627309">Mountain America Credit Union</option>
                          <option value="unsaddl_ins_114371">Heartland ECSI</option>
                          <option value="unsaddl_ins_764960">Unisa</option>
                          <option value="unsaddl_ins_240665">ELFI</option>
                          <option value="unsaddl_ins_053419">ELFI - AES</option>
                          <option value="unsaddl_ins_723277">Wright-Patt Credit Union</option>
                          <option value="ins_136573">Sloan Servicing</option>
                          <option value="ins_116949">Discover Student Loans</option>
                          <option value="unsaddl_ins_20614">CRI</option>
                          <option value="unsaddl_ins_255053">Meritize</option>
                          <option value="unsaddl_ins_303024">RISLA</option>
                        </select>
                      </div>

                      {error !== '' && (
                        <div className="mt-2">
                          <p className="text-sm text-red-500">{error}</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <LoadingButton
                    type="submit"
                    styles={
                      'hover:bg-green-700 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm'
                    }
                    text="Submit"
                    loadingText="Processing..."
                    isLoading={isSubmittingNewManualLoanServicer}
                  />
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default AddManualLoanServicerModal;
