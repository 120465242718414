import { Savings529Account } from 'interfaces/savings529Interfaces';
import React from 'react';
import { classNames } from 'utils/tailwindUtils';

interface ElectContributions529AccountProps {
  savings529Account: Savings529Account;
  contributionElectionAmountsBy529Account: { [key: string]: number };
  setContributionElectionAmountFor529Account: (savings529AccountId: string, amount: number) => void;
  contributionElectionPercentagesBy529Account: { [key: string]: number };
  setContributionElectionPercentageFor529Account: (savings529AccountId: string, amount: number) => void;
  isEditingContributions: boolean;
  electionType: string;
}

const ElectContributions529Account: React.FC<ElectContributions529AccountProps> = ({
  savings529Account,
  contributionElectionAmountsBy529Account,
  setContributionElectionAmountFor529Account,
  contributionElectionPercentagesBy529Account,
  setContributionElectionPercentageFor529Account,
  isEditingContributions,
  electionType,
}) => {
  const handleChangeAmountInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setContributionElectionAmountFor529Account(savings529Account.id, Number(e.target.value));
  };

  const handleChangePercentageInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setContributionElectionPercentageFor529Account(savings529Account.id, Number(e.target.value));
  };

  const electionAmount = contributionElectionAmountsBy529Account[savings529Account.id];
  const formattedElectionAmount = Number(electionAmount).toFixed(2);
  const percentage = contributionElectionPercentagesBy529Account[savings529Account.id];
  return (
    <div className="px-6 py-4 shadow-md ring-1 ring-black ring-opacity-10 rounded-lg bg-white">
      <h1 className="text-lg font-bold text-gray-900">{savings529Account.name}</h1>

      {savings529Account.contributionType === 'ach' ? (
        <table className="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              <th scope="col" className="py-3.5 pr-2 text-left text-sm font-semibold text-gray-500">
                Institution name
              </th>
              <th
                scope="col"
                className="py-3.5 px-2 text-right text-sm font-semibold text-gray-500 hidden xs:table-cell"
              >
                Routing #
              </th>
              <th
                scope="col"
                className="py-3.5 px-2 text-right text-sm font-semibold text-gray-500 hidden sm:table-cell"
              >
                Account #
              </th>
              <th scope="col" className="py-3.5 pl-2 text-right text-sm font-semibold text-gray-500">
                Your elections
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            <tr>
              <td className="pr-2 py-4 text-sm font-medium text-gray-900">{savings529Account.institutionName}</td>
              <td className="py-4 px-2 text-sm text-right text-gray-500 hidden xs:table-cell">
                {savings529Account.routingNumber}
              </td>
              <td className=" py-4 px-2 text-sm text-right text-gray-500 hidden sm:table-cell">
                {savings529Account.accountNumber}
              </td>

              <td className="relative py-4 pl-2 text-right text-sm font-medium">
                <div className="relative">
                  {electionType === 'amount' ? (
                    <>
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <span className="text-gray-500 text-sm">$</span>
                      </div>
                      <div>
                        {' '}
                        <input
                          type="number"
                          step=".01"
                          min="0.00"
                          max="1000000.00"
                          name="amount"
                          id="amount"
                          onChange={handleChangeAmountInput}
                          className={classNames(
                            isEditingContributions
                              ? 'border-indigo-500 text-black shadow-lg'
                              : 'text-gray-500 border-transparent',
                            'block w-full pl-7 pr-12 text-sm rounded-md'
                          )}
                          value={isEditingContributions ? electionAmount : formattedElectionAmount}
                          aria-describedby="price-currency"
                          disabled={!isEditingContributions}
                        />
                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                          <span className="text-gray-500 text-sm" id="price-currency">
                            USD
                          </span>
                        </div>{' '}
                      </div>
                    </>
                  ) : (
                    <>
                      <div>
                        <input
                          type="number"
                          step="1"
                          min="0"
                          max="100"
                          name="percentage"
                          id="percentage"
                          onChange={handleChangePercentageInput}
                          className={classNames(
                            isEditingContributions
                              ? 'border-indigo-500 text-black shadow-lg'
                              : 'text-gray-500 border-transparent',
                            'block w-full text-sm rounded-md'
                          )}
                          value={percentage}
                          aria-describedby="price-currency"
                          disabled={!isEditingContributions}
                        />{' '}
                      </div>
                      <div className="absolute inset-y-0 right-0 pr-8 flex items-center pointer-events-none">
                        <span className="text-gray-500 text-sm">%</span>
                      </div>
                    </>
                  )}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      ) : (
        <table className="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              <th scope="col" className="py-3.5 pr-2 text-left text-sm font-semibold text-gray-500">
                Institution name
              </th>
              <th
                scope="col"
                className="py-3.5 px-2 text-left text-sm font-semibold text-gray-500 hidden xs:table-cell"
              >
                Address
              </th>
              <th scope="col" className="py-3.5 px-2 text-right text-sm font-semibold text-gray-500">
                Account #
              </th>

              <th scope="col" className="py-3.5 pl-2 text-right text-sm font-semibold text-gray-500">
                Your elections
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            <tr>
              <td className="pr-2 py-4 text-sm font-medium text-gray-900">{savings529Account.institutionName}</td>
              <td className="py-4 px-2 text-sm text-left text-gray-500 hidden xs:table-cell">
                <div>{savings529Account.institutionAddressStreet}</div>
                {savings529Account.institutionAddressStreet2 && (
                  <div>{savings529Account.institutionAddressStreet2}</div>
                )}
                <div>
                  {savings529Account.institutionAddressCity}, {savings529Account.institutionAddressRegion}{' '}
                  {savings529Account.institutionAddressPostalCode}
                </div>
              </td>
              <td className=" py-4 px-2 text-sm text-right text-gray-500 ">{savings529Account.accountNumber}</td>

              <td className="relative py-4 pl-2 text-right text-sm font-medium">
                <div className="relative">
                  {electionType === 'amount' ? (
                    <>
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <span className="text-gray-500 text-sm">$</span>
                      </div>
                      <div>
                        {' '}
                        <input
                          type="number"
                          step=".01"
                          min="0.00"
                          max="1000000.00"
                          name="amount"
                          id="amount"
                          onChange={handleChangeAmountInput}
                          className={classNames(
                            isEditingContributions
                              ? 'border-indigo-500 text-black shadow-lg'
                              : 'text-gray-500 border-transparent',
                            'block w-full pl-7 pr-12 text-sm rounded-md'
                          )}
                          value={isEditingContributions ? electionAmount : formattedElectionAmount}
                          aria-describedby="price-currency"
                          disabled={!isEditingContributions}
                        />
                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                          <span className="text-gray-500 text-sm" id="price-currency">
                            USD
                          </span>
                        </div>{' '}
                      </div>
                    </>
                  ) : (
                    <>
                      <div>
                        <input
                          type="number"
                          step="1"
                          min="0"
                          max="100"
                          name="percentage"
                          id="percentage"
                          onChange={handleChangePercentageInput}
                          className={classNames(
                            isEditingContributions
                              ? 'border-indigo-500 text-black shadow-lg'
                              : 'text-gray-500 border-transparent',
                            'block w-full text-sm rounded-md'
                          )}
                          value={percentage}
                          aria-describedby="price-currency"
                          disabled={!isEditingContributions}
                        />{' '}
                      </div>
                      <div className="absolute inset-y-0 right-0 pr-8 flex items-center pointer-events-none">
                        <span className="text-gray-500 text-sm">%</span>
                      </div>
                    </>
                  )}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </div>
  );
};

export default ElectContributions529Account;
