import { Dialog, Transition } from '@headlessui/react';
import { CheckIcon, XIcon } from '@heroicons/react/outline';
import {
  apiSendTuitionReimbursementRequestCompletionReview,
  apiSendTuitionReimbursementRequestCourseReview,
} from 'api/tuitionReimbursementAPI';
import LoadingButton from 'components/Buttons/LoadingButton';
import { TuitionReimbursementRequest } from 'interfaces/tuitionReimbursementInterfaces';
import { Fragment, useState } from 'react';
import toast from 'react-hot-toast';

interface SubmitFeedbackModalProps {
  tuitionReimbursementRequest?: TuitionReimbursementRequest;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  approved: boolean;
}

const SubmitFeedbackModal: React.FC<SubmitFeedbackModalProps> = ({
  tuitionReimbursementRequest,
  open,
  setOpen,
  approved,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [values, setValues] = useState({
    summary: '',
    amount: tuitionReimbursementRequest?.courseCost ? tuitionReimbursementRequest.courseCost : 0,
  });
  const [error, setError] = useState('');

  const header =
    approved && tuitionReimbursementRequest?.reviewStatus === 'submitted'
      ? 'Are you sure you want to approve this course request?'
      : approved && tuitionReimbursementRequest?.reviewStatus === 'course_completed'
      ? 'Are you want to approve this course completion?'
      : !!!approved && tuitionReimbursementRequest?.reviewStatus === 'submitted'
      ? 'Are you sure you want to deny this course request?'
      : 'Are you sure you want to deny this course completion?';

  const buttonText = approved ? 'Approve' : 'Deny';

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const handleSubmitFeedback = async (event: React.SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();

    setIsSubmitting(true);
    if (tuitionReimbursementRequest?.reviewStatus === 'submitted') {
      apiSendTuitionReimbursementRequestCourseReview(
        tuitionReimbursementRequest.id,
        approved,
        values.summary,
        values.amount
      ).then(
        (data) => {
          setIsSubmitting(false);
          setOpen(false);
          setError('');
          toast.success(`Successfully submitted feedback for reimbursement request`);
        },
        (error) => {
          setIsSubmitting(false);
          setError(error.message);
        }
      );
    } else if (tuitionReimbursementRequest?.reviewStatus === 'course_completed') {
      apiSendTuitionReimbursementRequestCompletionReview(
        tuitionReimbursementRequest.id,
        approved,
        values.summary,
        values.amount
      ).then(
        (data) => {
          setIsSubmitting(false);
          setOpen(false);
          setError('');
          toast.success(`Successfully submitted feedback for reimbursement request`);
        },
        (error) => {
          setIsSubmitting(false);
          setError(error.message);
        }
      );
    }
  };

  const handleClose = () => {
    setOpen(false);
    setError('');
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={handleClose}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <form onSubmit={handleSubmitFeedback}>
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-indigo-100 sm:mx-0 sm:h-10 sm:w-10">
                      {approved ? (
                        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                          <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                        </div>
                      ) : (
                        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                          <XIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                        </div>
                      )}
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                        {header}
                      </Dialog.Title>

                      <div className="mt-4">
                        <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">
                          Summary
                        </label>
                        <div className="mt-1 border-b border-gray-300 focus-within:border-indigo-600">
                          <input
                            type="text"
                            name="summary"
                            id="summary"
                            onChange={handleChange}
                            value={values.summary}
                            className="block w-full border-0 border-b border-transparent bg-gray-50 focus:border-indigo-600 focus:ring-0 sm:text-sm"
                            required
                          />
                        </div>
                      </div>

                      {approved && (
                        <div className="mt-4">
                          <label htmlFor="price" className="block text-sm font-medium text-gray-700">
                            Amount
                          </label>
                          <div className="mt-1 relative rounded-md shadow-sm">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                              <span className="text-gray-500 sm:text-sm">$</span>
                            </div>
                            <input
                              type="number"
                              step=".01"
                              min="0.00"
                              max="1000000.00"
                              name="amount"
                              id="amount"
                              onChange={handleChange}
                              className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                              placeholder="0.00"
                              value={values.amount}
                              required
                              aria-describedby="price-currency"
                            />
                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                              <span className="text-gray-500 sm:text-sm" id="price-currency">
                                USD
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <LoadingButton
                    type="submit"
                    styles="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
                    text={buttonText}
                    loadingText="Processing..."
                    isLoading={isSubmitting}
                  />
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                </div>

                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  {error !== '' ? (
                    <div className="mt-2">
                      <p className="text-sm text-red-500">{error}</p>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default SubmitFeedbackModal;
