import { Dialog, Transition } from '@headlessui/react';
import { LightningBoltIcon } from '@heroicons/react/outline';
import { apiCreateNewTuitionReimbursementPlan } from 'api/tuitionReimbursementAPI';
import LoadingButton from 'components/Buttons/LoadingButton';
import { Fragment, useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';

interface CreateNewPlanModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateNewPlanModal: React.FC<CreateNewPlanModalProps> = ({ open, setOpen }) => {
  const history = useHistory();

  const [error, setError] = useState('');

  const [isSubmittingNewPlan, setIsSubmittingNewPlan] = useState(false);

  const [values, setValues] = useState({
    name: '',
    description: '',
    annualReimbursementMax: 0.0,
    creditHourMax: 0,
    eligibilityTenureDays: 0,
    instructions: '',
    terms: '',
    unsaddlManaged: false,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setValues((prevValues) => ({ ...prevValues, [name]: value }));
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = e.target;
    const checked = e.target.checked;
    setValues((prevValues) => ({ ...prevValues, [name]: checked }));
  };

  const handleClose = () => {
    setOpen(false);
    setError('');
  };

  const handleSubmitNewTuitionReimbursementPlan = async (event: React.SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();

    setIsSubmittingNewPlan(true);
    apiCreateNewTuitionReimbursementPlan(
      values.name,
      values.description,
      values.annualReimbursementMax,
      values.creditHourMax,
      values.eligibilityTenureDays,
      values.instructions,
      values.terms,
      values.unsaddlManaged
    ).then(
      (data) => {
        const newTuitionReimbursementPlan = data.newTuitionReimbursementPlan;
        setOpen(false);
        toast.success(`Successfully created new tuition reimbursement plan!`);
        history.push(`/tuition-reimbursement/plans/${newTuitionReimbursementPlan.id}/edit`);
        setIsSubmittingNewPlan(false);
      },
      (error) => {
        setError(error.message);
        setIsSubmittingNewPlan(false);
      }
    );
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={handleClose}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full">
              <form onSubmit={handleSubmitNewTuitionReimbursementPlan}>
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                      <LightningBoltIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                        Create New Tuition Reimbursement Plan
                      </Dialog.Title>

                      <div className="mt-4">
                        <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                          Name
                        </label>
                        <div className="mt-1 border-b border-gray-300 focus-within:border-indigo-600">
                          <input
                            type="text"
                            name="name"
                            id="name"
                            onChange={handleChange}
                            value={values.name}
                            className="block w-full border-0 border-b border-transparent bg-gray-50 focus:border-indigo-600 focus:ring-0 sm:text-sm"
                            required
                          />
                        </div>
                      </div>

                      <div className="mt-4">
                        <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                          Description (optional)
                        </label>
                        <div className="mt-1 border-b border-gray-300 focus-within:border-indigo-600">
                          <input
                            type="text"
                            name="description"
                            id="description"
                            onChange={handleChange}
                            value={values.description}
                            className="block w-full border-0 border-b border-transparent bg-gray-50 focus:border-indigo-600 focus:ring-0 sm:text-sm"
                          />
                        </div>
                      </div>

                      <div className="mt-4">
                        <label htmlFor="price" className="block text-sm font-medium text-gray-700">
                          Annual reimbursement maximum
                        </label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <span className="text-gray-500 sm:text-sm">$</span>
                          </div>
                          <input
                            type="number"
                            step=".01"
                            min="0.00"
                            max="1000000.00"
                            name="annualReimbursementMax"
                            id="annualReimbursementMax"
                            onChange={handleChange}
                            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                            placeholder="0.00"
                            value={values.annualReimbursementMax}
                            required
                            aria-describedby="price-currency"
                          />
                          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                            <span className="text-gray-500 sm:text-sm" id="price-currency">
                              USD
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="mt-4">
                        <label className="block text-sm font-medium text-gray-700">Credit hour maximum</label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                          <input
                            type="number"
                            step="1"
                            min="0"
                            max="10000"
                            name="creditHourMax"
                            id="creditHourMax"
                            onChange={handleChange}
                            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
                            placeholder="0"
                            value={values.creditHourMax}
                            required
                          />
                          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                            <span className="text-gray-500 sm:text-sm">hours</span>
                          </div>
                        </div>
                      </div>

                      <div className="mt-4">
                        <label className="block text-sm font-medium text-gray-700">Eligibility tenure</label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                          <input
                            type="number"
                            step="1"
                            min="0"
                            max="10000"
                            name="eligibilityTenureDays"
                            id="eligibilityTenureDays"
                            onChange={handleChange}
                            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
                            placeholder="0"
                            value={values.eligibilityTenureDays}
                            required
                          />
                          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                            <span className="text-gray-500 sm:text-sm">days</span>
                          </div>
                        </div>
                      </div>

                      <div className="mt-4">
                        <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                          Instructions
                        </label>
                        <div className="mt-1 border-gray-300 focus-within:border-indigo-600">
                          <textarea
                            id="instructions"
                            name="instructions"
                            rows={3}
                            onChange={handleChange}
                            className="block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:py-1.5 sm:text-sm sm:leading-6"
                            value={values.instructions}
                            placeholder="Add custom plan instructions"
                          />
                        </div>
                      </div>

                      <div className="mt-4">
                        <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                          Plan terms
                        </label>
                        <div className="mt-1 border-gray-300 focus-within:border-indigo-600">
                          <textarea
                            id="terms"
                            name="terms"
                            rows={3}
                            onChange={handleChange}
                            className="block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:py-1.5 sm:text-sm sm:leading-6"
                            value={values.terms}
                            placeholder="Add plan terms and conditions"
                          />
                        </div>
                      </div>

                      <div className="mt-4 relative flex items-start">
                        <div className="flex items-center h-5">
                          <input
                            id="unsaddlManaged"
                            name="unsaddlManaged"
                            type="checkbox"
                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                            checked={values.unsaddlManaged}
                            onChange={handleCheckboxChange}
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label htmlFor="contributeStudentLoansEnabled" className="font-medium text-gray-700">
                            Unsaddl manages requests
                          </label>
                        </div>
                      </div>

                      {error !== '' && (
                        <div className="mt-2">
                          <p className="text-sm text-red-500">{error}</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <LoadingButton
                    type="submit"
                    styles="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:ml-3 sm:w-auto sm:text-sm"
                    text="Submit"
                    loadingText="Processing..."
                    isLoading={isSubmittingNewPlan}
                  />
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default CreateNewPlanModal;
